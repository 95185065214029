<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-09-27 17:18:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="apply flex-h flex-vc">
    <aside>
      <div class="title">历史生成记录</div>
      <div v-if="list.length == 0" style="margin: 10px; text-align: center">
        暂无生成记录
      </div>
      <ul>
        <el-scrollbar style="height: 100%">
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="info(item.applyId)"
          >
            {{ $formatDate(item.applyTime) }}生成&nbsp;&nbsp;&nbsp;>>
          </li>
        </el-scrollbar>
      </ul>
    </aside>
    <div class="content">
      <img class="logo" src="@/assets/img/logo.png" />
      <div class="certificate">
        <h1>志愿服务证明</h1>
        <h3>(CETIFICATE OF VOLUNTARY SERVICE RECORD)</h3>
        <div class="certificate-box flex-h">
          <img :src="headImg" />
          <div class="box-right1">
            <p>姓名(NAME)</p>
            <p>性别(GENDER)</p>
            <p>志愿者编号(ID NO.)</p>
            <p>身份证号码(ID NO.)</p>
          </div>
          <div class="box-right2">
            <p>{{ volunteerName }}</p>
            <p>
              <span v-if="sex == 1">男</span>
              <span v-if="sex == 2">女</span>
            </p>
            <p>{{ volunteerCode }}</p>
            <p>{{ idCard }}</p>
          </div>
        </div>
        <h5>
          <span>累计志愿服务时长(LENGTH OF SERVICE)</span> {{ serverTime }}小时
        </h5>
        <h5>
          <span>参与志愿服务次数(NUMBER OF SERVICE)</span> {{ activityNum }}次
        </h5>
      </div>

      <div class="content-title" v-if="affirm == 2 || affirm == 3 || affirm == 1">
        志愿服务证明申请须知
      </div>
      <div class="content-desc" v-if="affirm == 2 || affirm == 3 || affirm == 1">
        1.志愿服务证明的申请要求：累计服务时长需大于10小时（包含10小时），才能申请服务证明。<br />
        2.所有者须为中华志愿者协会少儿艺术公益项目管理委员会平台用户，如果志愿者基本信息有误，请到“个人中心”中修改<br />
        3.纸质版服务证明到手后，请妥善保管，使用时请遵守相关规定和章程，如有违法违规等行为，中华志愿者协会少儿艺术公益项目管理委员会不承担法律后果。<br />
        4.本志愿服务证明最终解释权归中华志愿者协会少儿艺术公益项目管理委员会所有。
      </div>
      <div v-if="affirm == 2 || affirm == 3 || affirm == 1">
        <div
          class="button"
          @click="$router.push({ name: 'proveMaterials' })"
          v-if="serverTime >= 10"
        >
          申请我的服务证明
        </div>
        <div class="button2" v-else>申请我的服务证明</div>
      </div>
    </div>
    <el-dialog
      title="申请记录"
      :visible.sync="dialogFormVisible"
      center
      @close="close"
      width="670px"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="125px"
        class="demo-ruleForm"
        style="width: 450px"
        size="small"
      >
        <el-form-item label="服务证明生成时间">
          {{ $formatDate(ruleForm.applyTime) }}
        </el-form-item>
        <el-form-item label="状态">
          <span v-if="ruleForm.status == 0" style="color: blue">待派发</span>
          <span v-if="ruleForm.status == 1" style="color: green">已派发</span>
          <span v-if="ruleForm.status == 2" style="color: red">已拒绝</span>
          <span v-if="ruleForm.status == 3" style="color: orange">待审核</span>
        </el-form-item>
        <el-form-item v-if="ruleForm.status == 2" label="拒绝原因"
          >{{ ruleForm.remark }}
        </el-form-item>
        <el-form-item label="收货人姓名" prop="receiverName">
          <el-input
            v-if="ruleForm.status == 3"
            v-model="ruleForm.receiverName"
            maxlength="10"
            show-word-limit
            clearable
            placeholder="收货人姓名"
          ></el-input>
          <span v-else>
            {{ ruleForm.receiverName }}
          </span>
        </el-form-item>
        <el-form-item label="联系电话" prop="receiverPhone">
          <el-input
            v-if="ruleForm.status == 3"
            v-model="ruleForm.receiverPhone"
            maxlength="11"
            show-word-limit
            clearable
            placeholder="联系电话"
          ></el-input>
          <span v-else>
            {{ ruleForm.receiverPhone }}
          </span>
        </el-form-item>
        <el-form-item label="邮寄地址" prop="receiverAddress">
          <el-input
            v-if="ruleForm.status == 3"
            v-model="ruleForm.receiverAddress"
            maxlength="40"
            show-word-limit
            clearable
            placeholder="请输入邮寄地址"
            type="textarea"
          ></el-input>
          <span v-else>{{ ruleForm.receiverAddress }}</span>
        </el-form-item>
        <record
          :name="volunteerName"
          :sex="ruleForm.sex"
          :number="ruleForm.volunteerCode"
          :avatar="ruleForm.headImg"
          :num="ruleForm.serverTime"
        />
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          v-if="ruleForm.status == 3"
          type="primary"
          @click="submitForm('ruleForm')"
          >确认修改</el-button
        >
      </div>
    </el-dialog>
    <transition name="fade">
      <div class="poput" v-if="show">
        <div class="box">
          <div class="boxTitle">申请须知</div>
          <p>
            1、我们仅为会员志愿者和学生志愿者提供纸质版志愿服务证明，如需纸质版志愿服务证明，请申请成为会员志愿者和学生志愿者。
          </p>
          <p>
            2、根据《中华志愿者协会章程》，在第二届会员代表大会二次会议上，以无记名投票的方式调整了会费标准，会员志愿者会费为200元/年（学生志愿者无会费）；
          </p>
          <p>3、会员志愿者有哪些权利？</p>
          <p>* 本协会的选举权、被选举权和表决权；</p>
          <p>* 参加本协会组织的有关活动；</p>
          <p>* 获得本协会服务的优先权；</p>
          <p>* 对本协会工作的知情权、建议权和监督权；</p>
          <p>* 向本协会推荐会员；</p>
          <p>* 入会自愿、退会自由。</p>
          <div class="qodeBox flex-h flex-vc">
            <img src="@/assets/img/qr-pub.jpg" />
            <span
              >若您还有疑问，请添加少艺委指定工作人员微信，协助您完成会员志愿者的申请流程。申请不通过将无法为您制作会员志愿者证书。</span
            >
          </div>
          <div class="buttons flex-h flex-vc flex-hc">
            <div class="button1" @click="show = false">我知道了</div>
            <div class="button2" @click="$router.push({ name: 'auth' })">
              确认申请
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import record from "@/components/record.vue";
import { proveInfo, proveLogs, applyLogsInfo, proveUpdate } from "@/api/index";
const validatePhone = (rule, value, callback) => {
  if (!/^1\d{10}$/.test(value)) {
    callback(new Error("请输入正确格式手机号码"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      show: false,
      dialogFormVisible: false,
      headImg: "",
      idCard: "",
      serverTime: "",
      activityNum: "",
      sex: "",
      volunteerCode: "",
      volunteerName: "",
      list: [],
      ruleForm: {
        receiverName: "",
        receiverPhone: "",
        receiverAddress: "",
      },

      rules: {
        receiverName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        receiverPhone: [
          {
            required: true,
            validator: validatePhone,
            message: "请输入电话信息",
            trigger: "blur",
          },
        ],
        receiverAddress: [
          { required: true, message: "请输入邮寄地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    close() {
      this.ruleForm = {
        receiverName: "",
        receiverPhone: "",
        receiverAddress: "",
      };
    },
    getData() {
      proveInfo({ id: "" }).then((res) => {
        this.headImg = res.data.headImg;
        this.idCard = res.data.idCard;
        this.serverTime = res.data.serverTime == null ? 0 : res.data.serverTime;
        this.sex = res.data.sex;
        this.volunteerCode = res.data.volunteerCode;
        this.volunteerName = res.data.volunteerName;
        this.activityNum =
          res.data.activityNum == null ? 0 : res.data.activityNum;
      });
    },
    getList() {
      proveLogs().then((res) => {
        this.list = res.data;
      });
    },
    info(id) {
      applyLogsInfo({
        id,
      }).then((res) => {
        console.log(res);
        this.ruleForm = res.data;
        this.dialogFormVisible = true;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          proveUpdate(this.ruleForm).then((res) => {
            this.$alert(
              "我们将在7个工作日内为您盖章并寄出服务证书，请注意查收~",
              "提交成功",
              {
                confirmButtonText: "确定",
              }
            );
            this.dialogFormVisible = false;
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  computed: {
    affirm() {
      return this.$store.state.affirm;
    },
  },
  components: {
    record,
  },
  created() {
    this.getData();
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none;
}
.apply {
  height: 100%;
  aside {
    width: 210px;
    height: 100%;
    box-sizing: border-box;
    padding-top: 25px;
    .title {
      font-size: 18px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff6e6e;
      text-align: center;
      margin-top: 40px;
    }
    ul {
      height: 500px;
      margin-top: 25px;
      li {
        text-align: center;
        font-size: 13px;
        font-family: MicrosoftYaHei;
        color: #6b6a6a;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          color: rgba(255, 110, 110, 1);
        }
      }
    }
  }
  .content {
    width: 628px;
    height: 642px;
    background: #fff6f6;
    border-radius: 23px;
    box-sizing: border-box;
    padding: 25px;
    position: relative;
    .logo {
      width: 140px;
    }
    h1 {
      font-size: 25px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #010101;
      letter-spacing: 2px;
      margin-top: 15px;
      text-align: center;
    }
    h3 {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #636363;
      text-align: center;
    }
    .certificate {
      .certificate-box {
        align-items: flex-start;
        margin-top: 25px;
        margin-bottom: 17px;
        .box-right1 {
          width: 160px;
          margin-left: 40px;
        }
        .box-right2 {
          width: 180px;
        }
        img {
          width: 111px;
          height: 138px;
          margin-right: 10px;
        }
        p {
          height: 19px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #636363;
          margin-top: 14px;
        }
      }
      h5 {
        margin-top: 10px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #4a4a4a;
        span {
          display: inline-block;
          width: 318px;
        }
      }
    }

    .content-title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #ff6e6e;
      margin-top: 20px;
    }
    .content-desc {
      margin-top: 15px;
      height: 177px;
      background: #ffffff;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #636363;
      line-height: 26px;
    }
    .button {
      width: 170px;
      height: 37px;
      background: #ff6e6e;
      border-radius: 24px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 37px;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 5px;
    }
    .button2 {
      width: 170px;
      height: 37px;

      background: #d1c9c9;
      border-radius: 24px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 37px;
      cursor: pointer;
      margin: 0 auto;
      margin-top: 10px;
    }
  }
  .poput {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
    .box {
      width: 645px;
      height: 620px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 9px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 36px 70px 23px 70px;
      .boxTitle {
        font-size: 18px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #555555;
        text-align: center;
        margin-bottom: 36px;
      }
      p {
        margin-bottom: 15px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #716f6f;
        line-height: 19px;
      }
      .qodeBox {
        margin-top: 20px;
        img {
          width: 88px;
        }
        span {
          width: 448px;
          height: 32px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #efa954;
          line-height: 16px;
          margin-left: 22px;
        }
      }
    }
    .buttons {
      margin-top: 30px;
      .button1 {
        width: 112px;
        height: 32px;
        border-radius: 24px;
        border: 1px solid #ff6e6e;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ff6e6e;
        line-height: 32px;
        margin-right: 20px;
      }
      .button2 {
        width: 112px;
        height: 32px;
        background: #ff6e6e;
        border-radius: 24px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
        margin-left: 20px;
      }
    }
  }
}
</style>